import React from 'react';
import '../App.css';

import Chase from './components/chase'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Chase />
      </header>
    </div>
  );
}

export default App;
